<template>
  <div>
    <a-card title="New activated SKU ranking(First time to connect Omni)新增激活产品排名" style="width: 100%;">
      <div id="skuRanking"  style="width: 100%;height: 600px"></div>
    </a-card>

    <a-card title="New activated SKU date line chart(First time to connect Omni)新增激活产品数量同比/环比-按年" style="width: 100%;margin-top: 10px;">
      <div id="activatedSkuDate" style="width: 100%;height: 600px"></div>
    </a-card>

    <a-card title="Active SKU ranking(More than once time to connect Omni)活跃产品排名" style="width: 100%;margin-top: 10px;">
      <div id="activeSkuRanking"  style="width: 100%;height: 600px"></div>
    </a-card>

    <a-card title="Active SKU date line chart(More than once time to connect Omni)活跃产品数量同比/环比-按年" style="width: 100%;margin-top: 10px;">
      <div id="activeSkuDateLine" style="width: 100%;height: 600px"></div>
    </a-card>

    <a-card title="Active tank capacity ranking活跃产品排名-按油杯容量" style="width: 100%;margin-top: 10px;">
      <div id="activeTankCapacityRanking" style="width: 100%;height: 250px" ></div>
    </a-card>

    <a-card title="Active activation type ranking活跃产品排名-按启动方式" style="width: 100%;margin-top: 10px;">
      <div id="activeActivationType" style="width: 100%;height: 250px" ></div>
    </a-card>
  </div>
</template>

<script>
import {getSkuAnalysis} from "@/api/cigarette/largeScreen";
import * as echarts from "echarts";

export default {
  name: "brandSkuAnalysis",
  data() {
    return {}
  },
  created() {
    this.getAllPinbaifenxi();
  },
  methods:{
    getAllPinbaifenxi() {
      getSkuAnalysis().then(res =>{
        console.log(res);
        this.renderProductChart(res.skuRanking);
        this.renderActiveSkuRanking(res.activeSkuRanking);
        this.renderActiveProductsDateLineChart(res.todaySkuDateRankinLine,res.lastSkuDateRankinLine);
        this.renderActiveActiveSkuDateLine(res.todayActiveSkuDateRanking,res.lastActiveSkuDateRanking);
        this.renderTypeRanking(res.activeSkuRanking);
        this.renderTankCapacityRanking(res.activeActivationTypeRanking)
      })
    },
    renderTankCapacityRanking(activatedIngredients) {
      const chartDom = document.getElementById(`activeTankCapacityRanking`);
      const myChart = echarts.init(chartDom);
      const option =  {
        title: {
          text: 'Referer of a Website',
          subtext: 'Fake Data',
          left: 'center',
          show: false
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          bottom: '0',
          type: 'scroll',
          height:50
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: activatedIngredients,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderTypeRanking(activatedIngredients) {
      const chartDom = document.getElementById(`activeActivationType`);
      const myChart = echarts.init(chartDom);
      const option =  {
        title: {
          text: 'Referer of a Website',
          subtext: 'Fake Data',
          left: 'center',
          show: false
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          bottom: '0',
          type: 'scroll',
          height:50
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: activatedIngredients,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderActiveActiveSkuDateLine(todayYearData,lastYearData) {
      let lastToday = lastYearData.map(i => Number(i.value));
      let todayToday = todayYearData.map(i => Number(i.value));

      let growData = [];
      if(todayToday[0] === 0) {
        growData.push(0)
      } else {
        if(lastToday[0] === 0) {
          growData.push(todayToday[0] * 100)
        } else {
          growData.push((todayToday[0] / lastToday[0]).toFixed(0) * 100)
        }
      }

      if(todayToday[1] === 0) {
        growData.push(0)
      } else {
        if(lastToday[1] === 0) {
          growData.push(todayToday[1] * 100)
        } else {
          growData.push((todayToday[1] / lastToday[1]).toFixed(0) * 100)
        }
      }

      if(todayToday[2] === 0) {
        growData.push(0)
      } else {
        if(lastToday[2] === 0) {
          growData.push(todayToday[2] * 100)
        } else {
          growData.push((todayToday[2] / lastToday[2]).toFixed(0) * 100)
        }
      }

      if(todayToday[3] === 0) {
        growData.push(0)
      } else {
        if(lastToday[3] === 0) {
          growData.push(todayToday[3] * 100)
        } else {
          growData.push((todayToday[3] / lastToday[3]).toFixed(0) * 100)
        }
      }

      if(todayToday[4] === 0) {
        growData.push(0)
      } else {
        if(lastToday[4] === 0) {
          growData.push(todayToday[4] * 100)
        } else {
          growData.push((todayToday[4] / lastToday[4]).toFixed(0) * 100)
        }
      }

      if(todayToday[5] === 0) {
        growData.push(0)
      } else {
        if(lastToday[5] === 0) {
          growData.push(todayToday[5] * 100)
        } else {
          growData.push((todayToday[5] / lastToday[5]).toFixed(0) * 100)
        }
      }
      if(todayToday[6] === 0) {
        growData.push(0)
      } else {
        if(lastToday[6] === 0) {
          growData.push(todayToday[6] * 100)
        } else {
          growData.push((todayToday[6] / lastToday[6]).toFixed(0) * 100)
        }
      }

      if(todayToday[7] === 0) {
        growData.push(0)
      } else {
        if(lastToday[7] === 0) {
          growData.push(todayToday[7] * 100)
        } else {
          growData.push((todayToday[7] / lastToday[7]).toFixed(0) * 100)
        }
      }

      if(todayToday[8] === 0) {
        growData.push(0)
      } else {
        if(lastToday[8] === 0) {
          growData.push(todayToday[8] * 100)
        } else {
          growData.push((todayToday[8] / lastToday[8]).toFixed(0) * 100)
        }
      }

      if(todayToday[9] === 0) {
        growData.push(0)
      } else {
        console.log(todayToday[9])
        console.log(lastToday[9])
        if(lastToday[9] === 0) {
          growData.push(todayToday[9] * 100)
        } else {
          growData.push((todayToday[9] / lastToday[9]).toFixed(0) * 100)
        }
      }

      if(todayToday[10] === 0) {
        growData.push(0)
      } else {
        if(lastToday[10] === 0) {
          growData.push(todayToday[10] * 100)
        } else {
          growData.push((todayToday[10] / lastToday[10]).toFixed(0) * 100)
        }
      }

      if(todayToday[11] === 0) {
        growData.push(0)
      } else {
        if(lastToday[11] === 0) {
          growData.push(todayToday[11] * 100)
        } else {
          growData.push((todayToday[11] / lastToday[11]).toFixed(0) * 100)
        }
      }

      console.log('growData')
      console.log(growData)
      const chartDom = document.getElementById(`activeSkuDateLine`);
      const myChart = echarts.init(chartDom);
      const option =  {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {
          data: [lastYearData[0].name.split('-')[0], todayYearData[0].name.split('-')[0], 'Growth Rate'],
          bottom:'0',
        },
        xAxis: [
          {
            type: 'category',
            data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sept','Oct','Nov','Dec'],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
            min: 0,
            interval: 50,
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: '',
            min: 0,
            max: 100,
            interval: 20,
            axisLabel: {
              formatter: '{value}%'
            }
          }
        ],
        series: [
          {
            name: lastYearData[0].name.split('-')[0],
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + '';
              }
            },
            data: lastToday
          },
          {
            name: todayYearData[0].name.split('-')[0],
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + '';
              }
            },
            data: todayToday
          },
          {
            name: 'Growth Rate',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + '%';
              }
            },
            data: growData
          }
        ]
      };
      myChart.setOption(option);
    },
    renderActiveProductsDateLineChart(todayYearData,lastYearData) {
      let lastToday = lastYearData.map(i => Number(i.value));
      let todayToday = todayYearData.map(i => Number(i.value));

      let growData = [];
      if(todayToday[0] === 0) {
        growData.push(0)
      } else {
        if(lastToday[0] === 0) {
          growData.push(todayToday[0] * 100)
        } else {
          growData.push((todayToday[0] / lastToday[0]).toFixed(0) * 100)
        }
      }

      if(todayToday[1] === 0) {
        growData.push(0)
      } else {
        if(lastToday[1] === 0) {
          growData.push(todayToday[1] * 100)
        } else {
          growData.push((todayToday[1] / lastToday[1]).toFixed(0) * 100)
        }
      }

      if(todayToday[2] === 0) {
        growData.push(0)
      } else {
        if(lastToday[2] === 0) {
          growData.push(todayToday[2] * 100)
        } else {
          growData.push((todayToday[2] / lastToday[2]).toFixed(0) * 100)
        }
      }

      if(todayToday[3] === 0) {
        growData.push(0)
      } else {
        if(lastToday[3] === 0) {
          growData.push(todayToday[3] * 100)
        } else {
          growData.push((todayToday[3] / lastToday[3]).toFixed(0) * 100)
        }
      }

      if(todayToday[4] === 0) {
        growData.push(0)
      } else {
        if(lastToday[4] === 0) {
          growData.push(todayToday[4] * 100)
        } else {
          growData.push((todayToday[4] / lastToday[4]).toFixed(0) * 100)
        }
      }

      if(todayToday[5] === 0) {
        growData.push(0)
      } else {
        if(lastToday[5] === 0) {
          growData.push(todayToday[5] * 100)
        } else {
          growData.push((todayToday[5] / lastToday[5]).toFixed(0) * 100)
        }
      }
      if(todayToday[6] === 0) {
        growData.push(0)
      } else {
        if(lastToday[6] === 0) {
          growData.push(todayToday[6] * 100)
        } else {
          growData.push((todayToday[6] / lastToday[6]).toFixed(0) * 100)
        }
      }

      if(todayToday[7] === 0) {
        growData.push(0)
      } else {
        if(lastToday[7] === 0) {
          growData.push(todayToday[7] * 100)
        } else {
          growData.push((todayToday[7] / lastToday[7]).toFixed(0) * 100)
        }
      }

      if(todayToday[8] === 0) {
        growData.push(0)
      } else {
        if(lastToday[8] === 0) {
          growData.push(todayToday[8] * 100)
        } else {
          growData.push((todayToday[8] / lastToday[8]).toFixed(0) * 100)
        }
      }

      if(todayToday[9] === 0) {
        growData.push(0)
      } else {
        console.log(todayToday[9])
        console.log(lastToday[9])
        if(lastToday[9] === 0) {
          growData.push(todayToday[9] * 100)
        } else {
          growData.push((todayToday[9] / lastToday[9]).toFixed(0) * 100)
        }
      }

      if(todayToday[10] === 0) {
        growData.push(0)
      } else {
        if(lastToday[10] === 0) {
          growData.push(todayToday[10] * 100)
        } else {
          growData.push((todayToday[10] / lastToday[10]).toFixed(0) * 100)
        }
      }

      if(todayToday[11] === 0) {
        growData.push(0)
      } else {
        if(lastToday[11] === 0) {
          growData.push(todayToday[11] * 100)
        } else {
          growData.push((todayToday[11] / lastToday[11]).toFixed(0) * 100)
        }
      }

      console.log('growData')
      console.log(growData)
      const chartDom = document.getElementById(`activatedSkuDate`);
      const myChart = echarts.init(chartDom);
      const option =  {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {
          data: [lastYearData[0].name.split('-')[0], todayYearData[0].name.split('-')[0], 'Growth Rate'],
          bottom:'0',
        },
        xAxis: [
          {
            type: 'category',
            data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sept','Oct','Nov','Dec'],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
            min: 0,
            interval: 50,
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: '',
            min: 0,
            max: 100,
            interval: 20,
            axisLabel: {
              formatter: '{value}%'
            }
          }
        ],
        series: [
          {
            name: lastYearData[0].name.split('-')[0],
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + '';
              }
            },
            data: lastToday
          },
          {
            name: todayYearData[0].name.split('-')[0],
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + '';
              }
            },
            data: todayToday
          },
          {
            name: 'Growth Rate',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + '%';
              }
            },
            data: growData
          }
        ]
      };
      myChart.setOption(option);
    },
    renderActiveSkuRanking(item) {
      const chartDom = document.getElementById(`activeSkuRanking`);
      const myChart = echarts.init(chartDom);
      console.log(item.map(i => i.name));
      let nameList = item.map(i => i.name);
      const option = {
        xAxis: {
          type: 'category',
          data: nameList,
          axisLabel: {
            rotate: 15, // 根据需要调整角度
          },
          interval:0
        },
        yAxis: {
          type: 'value'
        },
        grid:{
          bottom:'80px',
          left:'120px'
        },
        series: [
          {
            data: item.map(i => i.value),
            type: 'bar'
          }
        ]
      };
      myChart.setOption(option);
    },
    renderProductChart(item) {
      const chartDom = document.getElementById(`skuRanking`);
      const myChart = echarts.init(chartDom);
      console.log(item.map(i => i.name));
      let nameList = item.map(i => i.name);
      const option = {
        xAxis: {
          type: 'category',
          data: nameList,
          axisLabel: {
            rotate: 15, // 根据需要调整角度
          },
          interval:0
        },
        yAxis: {
          type: 'value'
        },
        grid:{
          bottom:'80px',
          left:'120px'
        },
        series: [
          {
            data: item.map(i => i.value),
            type: 'bar'
          }
        ]
      };
      myChart.setOption(option);
    },
  }

}
</script>

<style scoped>

</style>